export const TRANSLATIONS_CS = {
    actions: "Plán akcí",
    cronicle: "Kronika",
    members: "Členové",
    contact: "Kontakt",
    commercials: "Videa",

    place: "Sídlo",
    chairman: "Předseda",
    vicechairman: "Místopředseda",
    treasurer: "Hospodář",
    quickContact: "Rychlý kontakt",
    hide: "Skrýt",
    read: "Přečíst",
    readAll: "Přečíst všechny",
    showAll: "Ukázat všechny",

    membersMessage: "Pořádně si je prohlédněte, než se rozhodnete děti k nám poslat. Ať pak vaše děti nestraší během školního roku.",
    cronicleMessage: "I my si už lecco nepamatujeme a proto si píšeme kroniku, abychom mohlispolečně zavzpomínat, jak nám bylo dobře.",
    contactMessage: "Důležité osoby Keltu. V případě jakéhokoliv dotazu se je nebojte kontaktovat.",
    commercialsMessage: "Malá video ukázka, co na těch táborech děláme.",    
    
    //login
    loginTitle: "Přihlášení",
    username: "Uživatel",
    password: "Heslo",
    login: "Přihlášení",
    loginBtn: "Přihlásit se",
    loginError: "Přihlášení se nezdařilo",
    invalidCredentials: "Neplatné uživatelské jméno nebo heslo",
    loginRequired: "Je vyžadováno přihlášení",
    close: "Zavřít",
    logout: "Odhlásit",
    signup: "Přihláška",
    edit: "Editovat",
    send: "Poslat",
    rental: "Půjčovna",
    scheduler: "Kalendář",
    position: "Místo",
    lastTime: "Naposledy",
    search: "Hledat",
    detail: "Detail",
    rentier: "Dlužník",
    from: "Od",
    to: "Do",
    save: "Uložit",
    new: "Nová",

    actionName: "Název akce",
    actionPlace: "Místo akce",
    actionText: "Popis akce",
    actionPrice: "Cena akce",
    showDecription: "Zobrazit popisek",

    digitalSignup: "Elektronická přihláška",
    digitalSignupMessage: "Přihlaška ve třech krocích",   
    chooseAction: "1. Vyberte akci",
    enterCaptcha: "2. Opište hodnotu",
    enterForm: "3. Vyplňte formulář",
    confirmForm: "4. Kontrola údajů",
    preview: "Náhled",
    confirm: "Potvrdit",

    captchaAlert: "Špatně opsaná hodnota, zkuste to ještě jednou",
    captchaPlaceholder: "Opište hodnotu z obrázku",
    reloadText: "Načíst znovu",
    submit: "Ověřit",

    navToogle: "Tlačitková navigace",
    about: "O uživateli",
    nick: "Přezdívka",
    func: "Funkce",
    moto: "Moto",
    text: "Text",
    email: "E-mail",
    birthDay: "Narozeniny",
    nameDay: "Svátek",
    docs: "Dokumenty",
    fotogalery: "Fotogalerie",
    dashboard: "Nástěnka",
    dashboardMessage: "To co bychom měli mít v hlavě a ne jen v databázi.",

    showHidden: "Zobrazit skryté",
    showEditor: "Zobrazit editor",
    dashboardName: "Titulek",
    dashboardVisibility: "Zobrazovat?",
    dashboardText: "Zpráva",
    lastUpdate: "Naposledy změnil",

    signupList: "Seznam přihlášených",
    created: "Změněno",    
    firstName: "Jméno",
    lastName: "Příjmení",
    birthNumber: "Rodné číslo (s lomítkem)",
    tShirtSize: "Velikost trička",
    address: "Adresa",
    parentName: "Jméno rodiče",
    parentPhone: "Tel. rodiče",
    parentEmail: "Email rodiče",
    note: "Poznámka",

};

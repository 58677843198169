import { useState, useEffect } from "react";
import Modal from "../UI/Modal";
import DatePicker from "react-datepicker";
import { actionInfo } from "./RentalItem";
import { DATABASE_MAIN } from "../contexts/api";
import { useTranslation } from "react-i18next";
import "../localization/i18n";
import "react-datepicker/dist/react-datepicker.css";

const RentalAction = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();

  const [rentier, setRentier] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [actionState, setActionState] = useState(props.item.state);
  const [actionStateName, setActionStateName] = useState("RENT");

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [place, setPlace] = useState("");
  const [state, setState] = useState("");
  const [lastRentier, setLastRentier] = useState("");

  useEffect(() => {
    if (props.item.state === "0") {
      setActionState("1");
      setActionStateName("RENT");
    } else {
      setActionState("0");
      setActionStateName("BACK");
    }
  }, [props.item.state]);

  const submitHandler = (event) => {
    event.preventDefault();
    let item = {};
    let canSave = false;

    if (isEdit) {
      item = {
        id: props.item.id,
        state: state,
        rentier: rentier,
        lastRentier: lastRentier,
        place: place,
        name: name,
        description: description,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
      };
      //setCanSave(true);
      canSave = true;
    }

    if (!isEdit && actionState === "1") {
      if (
        rentier.trim().length > 0 &&
        fromDate.toISOString().trim().length > 0 &&
        toDate.toISOString().trim().length > 0
      ) {
        item = {
          id: props.item.id,
          state: actionState,
          rentier: rentier,
          lastRentier: props.item.lastRentier,
          place: props.item.place,
          name: props.item.name,
          description: props.item.description,
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
        };
        //setCanSave(true);
        canSave = true;
      }
    }

    if (!isEdit && actionState === "0") {
      item = {
        id: props.item.id,
        state: actionState,
        rentier: "",
        lastRentier: props.item.rentier,
        place: props.item.place,
        name: props.item.name,
        description: props.item.description,
        fromDate: props.item.fromDate,
        toDate: props.item.toDate,
      };
      canSave = true;
    }

    if (canSave) {
      console.log(JSON.stringify(item));
      updateItem(item);
      props.onClose();
    }
  };

  async function updateItem(rentAction) {
    //await fetch('http://rental.oskelt.cz/items/update.php',{
    await fetch(`${DATABASE_MAIN}/updateRentalItem.php`, {
      method: "POST",
      body: JSON.stringify(rentAction),
      /* headers: {
        "content-type": "application/json",
      }, */
      mode: 'no-cors',
    }).catch((error) => console.log(error));

    //const res = await fetch('http://rental.oskelt.cz/items/update.php',{}
    //const data = await res.json().then(date=>console.log(date)).catch(error => console.log(error));
    //console.log(data);
  }

  const rentierChangeHandler = (event) => {
    setRentier(event.target.value);
  };
  const nameChangeHandler = (event) => {
    setName(event.target.value);
  };
  const descriptionChangeHandler = (event) => {
    setDescription(event.target.value);
  };
  const placeChangeHandler = (event) => {
    setPlace(event.target.value);
  };
  const stateChangeHandler = (event) => {
    setState(event.target.value);
  };
  const lastRentierChangeHandler = (event) => {
    setLastRentier(event.target.value);
  };

  const editHandler = () => {
    setIsEdit(true);
    setActionStateName("SAVE");
    setRentier(props.item.rentier);
    setName(props.item.name);
    setDescription(props.item.description);
    setPlace(props.item.place);
    setState(props.item.state);
    setLastRentier(props.item.lastRentier);
  };

  return (
    <Modal onClose={props.onClose}>
      <div className="row">
        <div className="col-sm">
          <h2 className="">{props.item.name}</h2>
          <h5>Status: {actionInfo(props.item.state)}</h5>
        </div>
        <div className="col-sm">
          <img
            className="rounded mx-auto p-2"
            src={`${DATABASE_MAIN}/rentalImage.php?id=${props.item.id}`}
            alt={props.item.name}
          />
        </div>
      </div>
      <form onSubmit={submitHandler}>
        <div className="form-floating mb-3 mt-3">
          <input
            type="text"
            className="form-control"
            id="rentier"
            placeholder="Enter Rentier"
            name="rentier"
            value={rentier}
            onChange={rentierChangeHandler}
          />
          <label htmlFor="rentier">{t("rentier")}</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <DatePicker
            className="form-control pb-3 pt-3"
            name="from"
            placeholderText={t("from")}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
          {/* <label htmlFor="from">{t("from")}:</label> */}
        </div>
        <div>
          <DatePicker
            className="form-control pb-3 pt-3"
            name="to"
            placeholderText={t("to")}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
          {/* <label htmlFor="to">{t("to")}:</label> */}
        </div>

        {isEdit && (
          <div>
            <div className="form-floating mb-3 mt-3">
              <input
                id="name"
                type="text"
                className="form-control"
                value={name}
                onChange={nameChangeHandler}
              />
              <label htmlFor="name">Name:</label>
            </div>
            <div className="form-floating mb-3 mt-3">
              <input
                id="description"
                type="text"
                className="form-control"
                value={description}
                onChange={descriptionChangeHandler}
              />
              <label htmlFor="description">Description:</label>
            </div>
            <div className="form-floating mb-3 mt-3">
              <input
                id="place"
                type="text"
                className="form-control"
                value={place}
                onChange={placeChangeHandler}
              />
              <label htmlFor="place">Place:</label>
            </div>
            <div className="form-floating mb-3 mt-3">
              <input
                id="state"
                type="text"
                className="form-control"
                value={state}
                onChange={stateChangeHandler}
              />
              <label htmlFor="state">State</label>
            </div>
            <div className="form-floating mb-3 mt-3">
              <input
                id="lastRentier"
                type="text"
                className="form-control"
                value={lastRentier}
                onChange={lastRentierChangeHandler}
              />
              <label htmlFor="lastRentier">Last Rentier</label>
            </div>
          </div>
        )}
        <div className="row justify-content-md-center">
          <div className="col-md-auto" >
            {!isEdit && <button className="btn btn-outline-primary" onClick={editHandler}>EDIT</button>}
            <button className="btn btn-outline-primary">{actionStateName}</button>
            <button className="btn btn-outline-primary" onClick={props.onClose}>{t("close")}</button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default RentalAction;
